<template>
    <div>
    <v-toolbar elevation="0">
        <v-toolbar-title>Surveys</v-toolbar-title>
    </v-toolbar>
<v-expansion-panels v-if="surveys.length">
    <v-expansion-panel
      v-for="s in surveys"
      :key="s.id"
    >
      <v-expansion-panel-header>
        {{s.title}}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card flat>
            <v-card-text>
                <div class="mb-4 text-caption">
                    {{ s.starts }} > {{ s.ends }}
                </div>
                <div v-html="s.description"></div>
                <v-card-actions style="display: inline-table;">
                    <v-btn
                        color="blue lighten-2"
                        @click="questions"
                        class="mb-3"
                    >
                        Questions
                    </v-btn>
                    <v-btn
                        color="green lighten-2"
                        @click="answers"
                        class="mb-3"
                    >
                        Answers
                    </v-btn>
                    <v-btn
                        color="orange lighten-2"
                        @click="assign"
                        class="mb-3"
                    >
                        Assign to Group
                    </v-btn>
                </v-card-actions>
                <div v-if="s.internal_notes" class="mb-4 text-caption" v-html="'<strong>Internal notes:</strong><br />' + s.internal_notes"></div>
            </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-card v-else>
    <v-card-text>There are currently no surveys configured.</v-card-text>
  </v-card>
    <v-btn
            color="pink"
            dark
            absolute
            bottom
            right
            fab
            class="add"
            >
        <v-icon>mdi-plus</v-icon>
    </v-btn>
    </div>
</template>
<style scoped>
.add {
    bottom: 15px !important;
}
</style>
<script>
  export default {
    data: () => ({
        surveys: [
        ],
    }),
    methods: {
        loadData: function(){
            var este = this;
            este.loading = true;
            var data = {
                id: this.$store.getters.login.id,
                token: this.$store.getters.login.token,
            }
            this.axios.post(this.$url + 'surveys', data).then(function(response){
                window.console.log(response.data.data);
                este.surveys = response.data.data;
                este.loading = false;
            }).catch(function(err){
                este.loading = false;
                if(typeof(err.response) !== 'undefined' && err.response.status == 401){
                    este.$store.commit('logout');
                }
                var errMsg;
                if(typeof(err.response) !== 'undefined'){
                    errMsg = err.response.data.msg;
                } else {
                    errMsg = 'There was an error. Please try again.';
                }
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": errMsg
                }
                este.$store.commit("msgMuestra", mensaje);
            });
        },
        questions: function(){},
        answers: function(){},
        assign: function(){},
    },
    mounted: function(){
        this.loadData();
    }
  }
</script>
